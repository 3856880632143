import { takeLeading, put, call } from 'redux-saga/effects';
import services from '../services';
import { SLIDER } from '../actionTypes';
import utils from 'utils';
import toast from 'utils/toast';
const { tranformToFormData } = utils;
const { FIND, CREATE, REMOVE, UPDATE, FIND_ONE } = SLIDER;

// admin sagas
export function* findSliderWatcher() {
  yield takeLeading(FIND.WATCH, findSliderWorker);
}
export function* finOnedSliderWatcher() {
  yield takeLeading(FIND_ONE.WATCH, findOneSliderWorker);
}
export function* createSliderWatcher() {
  yield takeLeading(CREATE.WATCH, createSliderWorker);
}

export function* updateSliderWatcher() {
  yield takeLeading(UPDATE.WATCH, updateSliderWorker);
}

export function* removeSliderWatcher() {
  yield takeLeading(REMOVE.WATCH, removeSliderWorker);
}
// website sagas

function* findSliderWorker({ payload }) {
  try {
    yield put({ type: FIND.LOAD });
    const response = yield call(services.slider.find, payload);
    yield put({ type: FIND.SUCCES, payload: { rows: response.data, count: response.count } });
  } catch (err) {
    toast.error(err);
    yield put({ type: FIND.FAIL, err });
  }
}

function* findOneSliderWorker({ payload }) {
  try {
    yield put({ type: FIND_ONE.LOAD });
    const response = yield call(services.slider.findOne, payload);
    yield put({ type: FIND_ONE.SUCCES, payload: response.data });
  } catch (err) {
    toast.error(err);
    yield put({ type: FIND_ONE.FAIL, err });
  }
}

function* createSliderWorker({ payload }) {
  try {
    const { body, cb, extra } = payload;
    yield put({ type: CREATE.LOAD });

    //first create request json
    const response = yield call(services.slider.post, tranformToFormData({ data: { image: extra.image, ...body } }));

    yield put({ type: CREATE.SUCCES, payload: response.data.result });
    toast.success();
    cb && cb();
  } catch (err) {
    toast.error(err);
    yield put({ type: CREATE.FAIL, err });
  }
}

function* updateSliderWorker({ payload }) {
  try {
    const { body, cb, extra, id, meta } = payload;
    yield put({ type: UPDATE.LOAD });

    //first create request json
    const response = yield call(services.slider.put, { body: tranformToFormData({ data: body }), id });
    // second update image after create success
    const fileResponse =
      response.success && extra.image && extra.image.length && !extra.image[0].uploaded
        ? yield call(services.slider.put, {
            body: tranformToFormData({ data: { image: extra.image } }),
            id,
          })
        : null;

    const filesDeleted =
      response.success && meta.imageDeleted
        ? yield call(services.slider.put, {
            body: tranformToFormData({ data: { image: '' } }),
            id,
          })
        : null;
    yield put({ type: UPDATE.SUCCES, payload: response.data.result });
    toast.success();
    cb && cb();
  } catch (err) {
    toast.error(err);
    yield put({ type: UPDATE.FAIL, err });
  }
}

function* removeSliderWorker({ payload }) {
  try {
    yield put({ type: REMOVE.LOAD });
    yield call(services.slider.delete, payload);
    yield put({ type: REMOVE.SUCCES, payload });
  } catch (err) {
    toast.error(err);
    yield put({ type: REMOVE.FAIL, err });
  }
}
