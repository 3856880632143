import React from 'react';
import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import utils from 'utils';

const FailScreen = () => {
  const { t, i18n } = useTranslation();
  return (
    <Result
      status='warning'
      title={t('something_went_wrong')}
      extra={
        <Button
          type='primary'
          children={t('return_homepage')}
          onClick={() => window.location.assign(utils.getLink('/', i18n.language))}
        />
      }
    />
  );
};

export default FailScreen;
