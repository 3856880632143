import { languages } from 'constants/localization';

export const resources = {
  [languages.en.code]: {
    translation: {
      // global
      aniv: 'ANIV',
      reset: 'Reset filter',
      delete_popconfirm_text: 'Are you sure?',
      popconfirm_ok_text: 'Yes',
      popconfirm_cancel_text: 'No',
      operation_completed: 'Operation completed',
      armenia: 'Armenia',
      russia: 'Russia ',
      no_data: 'No data',
      page_not_found: 'Page Not Found',
      return_homepage: 'Back to Homepage',
      email: 'Email',
      password: 'password',
      login: 'Log in',
      logout: 'Log out',
      settings: 'Settings',
      are_you_sure: 'Are you sure ?',
      archive: 'archive',
      tags: 'Tags',
      show_more: 'SHOW MORE',
      requiredField: 'Required field',
      requiredFieldsMessage: 'Fields with ( * ) are required',
      enterValidEmail: 'Enter valid email',
      enterValidUrl: 'Enter valid url',
      enterValidNumber: 'Value must be greater then or equal to 0',
      journalId: 'Journal number',
      name_input_max_length_message: 'The text is too long. Max. text size 30 symbols.',
      valid_phone_message: 'Invalid phone number format! Correct format (+123 ...)',
      select: 'Select',
      rubrics: 'Основные теги',
      search_result_for: 'Search result for',
      search: 'Search ...',
      organizations: 'Organizations',
      persons: 'Council members',
      logo: 'Logo',
      submit: 'Save',
      no_vacancies: 'No open vacancies ',
      slider: 'Slider',
      //   header
      logo_text: 'Foundation for Development and Support of Armenian Studies',
      about: 'About us',
      homepage: 'Main page',
      org_structure: 'Organizational structure',
      trustee_council: 'Board of Trustees',
      science_council: 'Scientific Council',
      science_council_russia: 'Scientific Council Russia',
      partners: 'Partners',
      activity: 'Activities',
      confirmed_projects: 'Completed projects',
      current_projects: 'Ongoing projects',
      events: 'Events',
      materials: 'Materials',
      books: 'Books',
      articles: 'Articles',
      digital_library: "Aniv's e-Library",
      bibliography: 'Bibliography',
      useful_links: 'Useful links',
      archive_aniv: 'Aniv Magazine Archive',
      news: 'News',
      contact: 'Contacts',
      vacancies: 'Vacancies',
      // about page
      fund_history: 'Fund history en',
      fund_mission: 'Миссия фонда en',
      fund_goals: 'Цели и задачи en',
      download: 'Download file',
      open: 'Open',
      link_to_personal_page: 'Link to a personal page',
      short_description: 'Short description',
      full_description: 'Full description',
      share: 'Share',
      fund_leadership: 'Руководство Фонда en',
      rank: 'Rank',
      scientific_degree: 'Academic degree',
      previous_job: 'Previous jobs',
      full_address: 'Country, city, address',
      rank_work: 'Должность/Работа',
      organization_name: 'Organization name',
      link_to_website: 'Link to website',
      // activities page
      announced: 'Announced',
      current: 'Current',
      completed: 'Completed',
      announced_events: 'Event announcements',
      all_events: 'All events',
      activity_description:
        'Центральное место в нашей деятельности занимает организация и проведение научных исследований по арменоведению. Большое внимание уделяется вопросам, связанным с сохранением и популяризацией историко-культурного наследия армянского народа посредством издания научных работ, каталогов, альбомов, карт, архивных документов, переводов и переизданий научных и научно-популярных книг. Совместно с нашими партнёрами или самостоятельно мы проводим на регулярной основе международные конференции по актуальным и перспективным направлениям арменоведения, организуем научные командировки и исследовательские экспедиции. Большое внимание уделяется поддержке молодых учёных посредством выделения премий и стипендий, исследовательских грантов, грантов на научные командировки, издание научных работ и другие нужды.',
      // contact page
      full_name: 'First and last name',
      phone_number: 'Phone number',
      subject: 'Topic',
      message: 'Message',
      send: 'Send',
      address: 'Address',
      other_details: 'Other information',
      contact_us: 'Contact us',
      // main page
      about_fund: 'About fund en',
      about_fund_description:
        'Фонд «Анив» – некоммерческая, негосударственная общественная организация, действующая на добровольных началах. Фонд является самоуправляющейся структурой, основанной на взаимодействии и сотрудничестве учёных, представителей государственных органов и бизнеса из разных стран мира, объединённых общностью интересов в плане изучения и популяризации армянского историко-культурного наследия.',
      // materials page
      materials_title: 'Armenian studies materials',
      book_author: 'Author',
      read_also: 'Read also',
      download_PDF: 'Download PDF file',
      link_for_amazon: 'Go to Amazon',
      link_for_lit_res: 'Go to LitRes',
      book_name: 'Book name en',
      // news page
      announcement: 'news announcement en',
      planned_activities: 'Planned events',

      other_news: 'Other news',
      // vacancies
      job_title: 'Job Title:',
      job_placement: 'Organization:',
      job_date_from: 'Until:',
      job_status_description: 'DESCRIPTION OF POSITION:',
      job_responsibilities: 'JOB RESPONSIBILITIES:',
      job_qualifications: 'REQUIRED QUALIFICATIONS:',
      apply: 'Apply',
      resume: 'Resume',
      resume_not_attached: 'No resume selected',
      job_name: 'Job Title',
      job_desc: 'Job description',
      work_place: 'Place of work',
      deadline: 'Application deadline',

      // footer
      subscribe: 'Subscribe',
      unsubscribe: 'Unsubscribe',
      privacy_policy: 'Privacy policy',
      terms_of_use: 'Terms of use',

      // months
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',

      //toast message
      operation_success: 'Successfully sent',
      operation_fail: 'Something went wrong. Please try again',
      // admin columns
      title: 'Title',
      status: 'Status',
      created: 'Created',
      actions: 'Actions',
      tag_name: 'Tagname',
      name: 'Name',
      // admin menu
      digital_library_aniv: `Aniv's e-Library "Books"`,
      digital_library_articles: `e-Library "Articles"`,
      bibliography_books: 'Bibliography/Books',
      bibliography_articles: 'Bibliography/Articles',
      //other
      firstname: 'Firstname',
      surname: 'Surname',
      patronymic: 'Patronymic',
      position: 'Job/Position',
      bio: 'Biography',
      image: 'Image',
      cover: 'Cover image',
      country: 'Country',
      council: 'Council',
      add_social: 'Add social-page link',
      social_page_name: 'Social page name',
      social_pages: 'Social pages',
      social_page_link: 'Link',
      source_name: 'Source name',
      source_link: 'Source link',
      location: 'Լocation',
      start_end_date: 'Start/End Date',
      pub_house: 'Publishing house',
      date: 'Date',
      docs: 'Documents',
      link_name: 'Link text',
      link: 'Link',
      add_link: 'Add link',
      add_record: 'Add record',
      click_to_upload: 'Click to upload',
      something_went_wrong: 'Something went wrong',
      description: 'Description',
      add_item: 'Add item',
      date_to: 'Expiration date',
      max_file_size_5: 'Invalid file size, max 5mb',
      activities: 'Activities',
      button_text: 'Button text',
      button_url: 'Button url',
    },
  },

  [languages.ru.code]: {
    translation: {
      // global
      aniv: 'АНИВ',
      reset: 'Сбросить фильтр',
      delete_popconfirm_text: 'Вы уверены ?',
      popconfirm_ok_text: 'Да',
      popconfirm_cancel_text: 'Нет',
      operation_completed: 'Операция выполнена',
      armenia: 'Армения',
      russia: 'Россия ',
      no_data: 'Нет данных',
      page_not_found: 'Страница не найдена',
      return_homepage: 'Вернуться на главную страницу',
      login: 'Войти',
      logout: 'Выйти',
      email: 'Эл. почта',
      password: 'Пароль',
      settings: 'Настройки',
      are_you_sure: 'Вы уверены?',
      archive: 'Архив',
      tags: 'Метки',
      show_more: 'ПОКАЗАТЬ БОЛЬШЕ',
      requiredField: 'Обязательное поле',
      requiredFieldsMessage: 'Поля с (*) обязательны для заполнения.',
      enterValidEmail: 'Введите Ваш e-mail',
      enterValidUrl: 'Введите действительный URL',
      enterValidNumber: 'Значение должно быть больше или равно 0',
      journalId: 'Номер журнала',
      name_input_max_length_message: 'Текст слишком длинный. Максимум размер текста 30 символов.',
      valid_phone_message: 'Неверный формат номера телефона! Корректный формат  (+123...)',
      select: 'Выбрать',
      rubrics: 'Основные теги',
      search_result_for: 'Результаты поиска для',
      search: 'Поиск ...',
      organizations: 'Организации',
      persons: 'Члены совета',
      logo: 'Логотип',
      submit: 'Сохранить',
      no_vacancies: 'Нет свободных вакансий ',
      slider: 'Слайдер',
      //   header
      logo_text: 'ФОНД РАЗВИТИЯ И ПОДДЕРЖКИ АРМЕНОВЕДЧЕСКИХ ИССЛЕДОВАНИЙ',
      about: 'О нас',
      homepage: 'Главная страница',
      org_structure: 'Оргструктура',
      trustee_council: 'Попечительский совет',
      science_council: 'Научный совет',
      science_council_russia: 'Научный совет Россия',
      partners: 'Партнёры',
      activity: 'Деятельность',
      confirmed_projects: 'Завершённые проекты',
      current_projects: 'Текущие проекты',
      events: 'Мероприятия',
      materials: 'Материалы',
      books: 'Книги',
      articles: 'Статьи',
      digital_library: 'Электронная библиотека "Анив"',
      bibliography: 'Библиография',
      useful_links: 'Полезные ссылки',
      archive_aniv: 'Архив Журнала "Анив"',
      contact: 'Контакты',
      news: 'Новости',
      vacancies: 'Вакансии',
      // about page
      fund_history: 'История фонда',
      fund_mission: 'Миссия фонда',
      fund_goals: 'Цели и задачи',
      download: 'Скачать файл',
      open: 'Открыть',
      link_to_personal_page: 'Ссылка на личную страницу',
      short_description: 'Краткое описание',
      full_description: 'Полное описание',
      share: 'Поделиться',
      fund_leadership: 'Руководство Фонда',
      rank: 'Звание',
      scientific_degree: 'Учёная степень',
      previous_job: 'Предыдущие места работы',
      full_address: 'Страна, город, адрес',
      rank_work: 'Должность/Работа',

      organization_name: 'Название организации',
      link_to_website: 'Ссылка на сайт',
      // activities page
      announced: 'Анонсируемые',
      current: 'Текущиe',
      completed: 'Завершённые',

      announced_events: 'Анонсируемые мероприятия',
      all_events: 'Все мероприятия',
      activity_description:
        'Центральное место в нашей деятельности занимает организация и проведение научных исследований по арменоведению. Большое внимание уделяется вопросам, связанным с сохранением и популяризацией историко-культурного наследия армянского народа посредством издания научных работ, каталогов, альбомов, карт, архивных документов, переводов и переизданий научных и научно-популярных книг. Совместно с нашими партнёрами или самостоятельно мы проводим на регулярной основе международные конференции по актуальным и перспективным направлениям арменоведения, организуем научные командировки и исследовательские экспедиции. Большое внимание уделяется поддержке молодых учёных посредством выделения премий и стипендий, исследовательских грантов, грантов на научные командировки, издание научных работ и другие нужды.',
      // contact page
      full_name: 'Имя и фамилия',
      phone_number: 'Номер телефона',
      subject: 'Тема',
      message: 'Сообщение',
      send: 'Отправить',
      address: 'Адрес',
      other_details: 'Другие данные',
      contact_us: 'Написать нам',
      // main page
      about_fund: 'О фонде',
      about_fund_description:
        'Фонд «Анив» – некоммерческая, негосударственная общественная организация, действующая на добровольных началах. Фонд является самоуправляющейся структурой, основанной на взаимодействии и сотрудничестве учёных, представителей государственных органов и бизнеса из разных стран мира, объединённых общностью интересов в плане изучения и популяризации армянского историко-культурного наследия.',
      // materials page
      materials_title: 'Материалы по арменоведeнию',
      book_author: 'Автор',
      read_also: 'Читайте также',
      download_PDF: 'Скачать PDF файл',
      link_for_amazon: 'Ссылка для Amazon',
      link_for_lit_res: 'Ссылка для LitRes',
      book_name: 'Название книги',
      // news page
      announcement: 'Обявления',
      planned_activities: 'Планируемые мероприятия',
      other_news: 'Другие новости',
      // vacancies
      job_title: 'Имя работы:',
      job_placement: 'Место работы:',
      job_date_from: 'До:',
      job_status_description: 'ОПИСАНИЕ ДОЛЖНОСТИ:',
      job_responsibilities: 'СЛУЖЕБНЫЕ ОБЯЗАННОСТИ:',
      job_qualifications: 'ТРЕБУЕМАЯ КВАЛИФИКАЦИЯ:',
      apply: 'Подать заявление',
      resume: 'Резюме',
      resume_not_attached: 'Резюме не выбран',
      job_name: 'Имя работы',
      job_desc: 'Описание должности',
      work_place: 'Место работы',
      deadline: 'Срок подачи заявок',
      // footer
      subscribe: 'Подписаться',
      unsubscribe: 'Отписаться',
      privacy_policy: 'Политика конфиденциальности',
      terms_of_use: 'Условия использования',

      // months
      January: 'Январь',
      February: 'Февраль',
      March: 'Март',
      April: 'Апрель',
      May: 'Май',
      June: 'Июнь',
      July: 'Июль',
      August: 'Август',
      September: 'Сентябрь',
      October: 'Октябрь',
      November: 'Ноябрь',
      December: 'Декабрь',

      //toast message
      operation_success: 'Успешно отправлено',
      operation_fail: 'Что то пошло не так. Попробуйте снова',
      // admin columns
      title: 'Заголовок',
      status: 'Статус',
      created: 'Дата создания',
      actions: 'Действия',
      tag_name: 'Название тега',
      name: 'Имя',
      // admin menu
      digital_library_aniv: `Эл. библиотека "Анив"`,
      digital_library_articles: `Эл. библиотека "Статьи"`,
      bibliography_books: 'Библиография/Книги',
      bibliography_articles: 'Библиография/Статьи',
      // other
      firstname: 'Имя',
      surname: 'Фамилия',
      patronymic: 'Отчество',
      position: 'Работа/Должность',
      bio: 'Биография',
      image: 'Фото',
      cover: 'Изображение обложки',
      country: 'Страна',
      council: 'Совет',
      add_social: 'Добавить станицу в соц-сетях',
      social_page_name: 'Название соц-сети',
      social_page_link: 'Ссылка',
      social_pages: 'Соц-сети',
      source_name: 'Имя источника',
      source_link: 'Ссылка источника',
      location: 'Местонахождение',
      start_end_date: 'Дата начала/окончания',
      pub_house: 'Издательство',
      date: 'Дата',
      docs: 'Документы',
      link_name: 'Текст ссылки',
      link: 'Ссылка',
      add_link: 'Добавить ссылку',
      add_record: 'Добавить запись',
      click_to_upload: 'Кликните для загрузки',
      something_went_wrong: 'Что-то пошло не так',
      description: 'Описание',
      add_item: 'Добавить пункт',
      date_to: 'Срок окончания',
      max_file_size_5: 'Неверный размер файла, максимальный размер 5 МБ',
      activities: 'Деятельность',
      button_text: 'Текст кнопки',
      button_url: 'Ссылка кнопки',
    },
  },

  [languages.am.code]: {
    translation: {
      // global
      aniv: 'ԱՆԻՎ',
      reset: 'Զրոյացնել զտիչը',
      delete_popconfirm_text: 'Դուք համոզված եք ?',
      popconfirm_ok_text: 'Այո',
      popconfirm_cancel_text: 'Ոչ',
      operation_completed: 'Գործողությունը կատարված է',
      armenia: 'Հայաստան',
      russia: 'Ռուսաստան ',
      no_data: 'Տվյալներ չեն գտնվել',
      page_not_found: 'Էջը չի գտնվել',
      return_homepage: 'Վերադառնալ գլխավոր էջ',
      login: 'Մուտք',
      logout: 'Դուրս գալ',
      email: 'էլ. հասցե',
      password: 'Գաղտնաբառ',
      settings: 'Կարգավորումներ',
      are_you_sure: 'Համոզվա՞ծ եք:',
      archive: 'արխիվ',
      tags: 'Թեգեր',
      show_more: 'ՑՈՒՅՑ ՏԱԼ ԱՎԵԼԻՆ',
      requiredField: 'Պարտադիր դաշտ',
      requiredFieldsMessage: '(*) Դաշտերը պարտադիր են:',
      enterValidEmail: 'Մուտքագրեք վավեր էլեկտրոնային հասցե',
      enterValidUrl: 'Մուտքագրեք վավեր հղում',
      enterValidNumber: 'Արժեքը պետք է մեծ կամ հավասար լինի 0-ի',
      journalId: 'Ամսագրի համարը',

      name_input_max_length_message: 'Տեքստը շատ երկար է։ Տեքստի մաքսիմում չափը 30 սիմվոլ',
      valid_phone_message: 'Համարի սխալ ձևաչափ։ Ճիշտ ձևաչափը՝ (+123...)',
      select: 'Ընտրել',
      rubrics: 'Основные теги',
      search_result_for: 'Որոնման արդյունքները',
      search: 'Որոնում ...',
      organizations: 'Կազմակերպություններ',
      persons: 'Խորհրդի անդամներ',
      logo: 'Լոգո',
      submit: 'Պահպանել',
      no_vacancies: 'Ազատ հաստիքներ չկան ',
      slider: 'Պատկերասրահ',
      //   header
      logo_text: 'Հայագիտական հետազոտությունների զարգացման և աջակցության հիմնադրամ',
      about: 'Մեր մասին',
      homepage: 'Հիմնական էջ',
      org_structure: 'Կազմակերպչական կառուցվածքը',
      trustee_council: 'Հոգաբարձուների խորհուրդ',
      science_council: 'Գիտական ​​խորհուրդ',
      science_council_russia: 'Գիտական ​​խորհուրդ Ռուսաստան',
      partners: 'Գործընկերները',
      activity: 'Գործունեություն',
      confirmed_projects: 'Ավարտված նախագծերը',
      current_projects: 'Ընթացիկ ծրագրեր',
      events: 'Իրադարձություններ',
      materials: 'Նյութեր',
      books: 'Գրքեր',
      articles: 'Հոդվածներ',
      digital_library: '«Անիվ» էլեկտրոնային գրադարան',
      bibliography: 'Մատենագրություն',
      useful_links: 'Oգտակար հղումներ',
      archive_aniv: '«Անիվ» ամսագրի արխիվ',
      news: 'Նորություններ',
      contact: 'Կոնտակտներ',
      vacancies: 'Աշխատատեղեր',
      // about page
      fund_history: 'История фонда am',
      fund_mission: 'Миссия фонда am',
      fund_goals: 'Цели и задачи am',
      download: 'Ներբեռնել ֆայլը',
      open: 'Բացել',
      link_to_personal_page: 'Անձնական էջի հղում',
      short_description: 'Կարճ նկարագրություն',
      full_description: 'Ամբողջական նկարագրությունը',
      share: 'Կիսվել',
      fund_leadership: 'Руководство Фонда am',
      rank: 'Աստիճան',
      scientific_degree: 'Գիտական ​​աստիճան',
      previous_job: 'Նախկին աշխատանքները',
      full_address: 'Երկիր, քաղաք, հասցե',
      rank_work: 'Должность/Работа',
      organization_name: 'Կազմակերպության անվանում',
      link_to_website: 'Կայքի հղումը',
      // activities page
      announced: 'Анонсируемые',
      current: 'Текущиe',
      completed: 'Завершённые',
      announced_events: 'Հայտարարված միջոցառումներ',
      all_events: 'Բոլոր իրադարձությունները',
      activity_description:
        'Центральное место в нашей деятельности занимает организация и проведение научных исследований по арменоведению. Большое внимание уделяется вопросам, связанным с сохранением и популяризацией историко-культурного наследия армянского народа посредством издания научных работ, каталогов, альбомов, карт, архивных документов, переводов и переизданий научных и научно-популярных книг. Совместно с нашими партнёрами или самостоятельно мы проводим на регулярной основе международные конференции по актуальным и перспективным направлениям арменоведения, организуем научные командировки и исследовательские экспедиции. Большое внимание уделяется поддержке молодых учёных посредством выделения премий и стипендий, исследовательских грантов, грантов на научные командировки, издание научных работ и другие нужды.',
      // contact page
      full_name: 'Անուն և ազգանուն',
      phone_number: 'Հեռախոսահամար',
      // subject: 'Առարկա',
      subject: 'Թեմա',
      message: 'Հաղորդագրություն',
      send: 'Ուղարկել',
      address: 'Հասցեն',
      other_details: 'Այլ տվյալներ',
      contact_us: 'Գրեք մեզ',
      // main page
      about_fund: 'О фонде am',
      about_fund_description:
        'Фонд «Анив» – некоммерческая, негосударственная общественная организация, действующая на добровольных началах. Фонд является самоуправляющейся структурой, основанной на взаимодействии и сотрудничестве учёных, представителей государственных органов и бизнеса из разных стран мира, объединённых общностью интересов в плане изучения и популяризации армянского историко-культурного наследия.',
      // materials page
      materials_title: 'Նյութեր հայագիտության վերաբերյալ',
      book_author: 'Հեղինակ',
      read_also: 'Կարդացեք նաև',
      download_PDF: 'Ներբեռնեք PDF ֆայլ',
      link_for_amazon: 'Հղում Amazon- ի համար',
      link_for_lit_res: 'Հղում LitRes-ին',
      book_name: 'Book name am',
      // news page
      announcement: 'Обявления am',
      planned_activities: 'Պլանավորված գործողություններ',
      other_news: 'Այլ նորություններ',
      // vacancies
      job_title: 'Աշխատանքի անվանումը:',
      job_placement: 'Աշխատանքի վայրը:',
      job_date_from: 'Նախքան:',
      job_status_description: 'ԿԱՐԳԱՎԻՃԱԿԻ ՆԿԱՐԱԳՐՈՒԹՅՈՒՆ:',
      job_responsibilities: 'ԱՇԽԱՏԱՆՔԱՅԻՆ ՊԱՐՏԱԿԱՆՈՒԹՅՈՒՆՆԵՐ:',
      job_qualifications: 'ՊԱՀԱՆՋՎՈՂ ՈՐԱԿԱՎՈՐՈՒՄՆԵՐ:',
      apply: 'Դիմել',
      resume: 'Ռեզյումե',
      resume_not_attached: 'Ոչ մի ռեզյումե ընտրված չէ',
      job_name: 'Աշխատանքի անվանումը',
      job_desc: 'Աշխատանքի նկարագրություն',
      work_place: 'Աշխատանքի վայրը',
      deadline: 'Դիմելու վերջնաժամկետ',
      // footer
      subscribe: 'Բաժանորդագրվել',
      unsubscribe: 'Չեղարկել բաժանորդագրությունը',
      privacy_policy: 'Գաղտնիության քաղաքականություն',
      terms_of_use: 'Օգտագործման պայմաններ',
      // months
      January: 'Հունվար',
      February: 'Փետրվար',
      March: 'Մարտ',
      April: 'Ապրիլ',
      May: 'Մայիս',
      June: 'Հունիս',
      July: 'Հուլիս',
      August: 'Օգոստոս',
      September: 'Սեպտեմբեր',
      October: 'Հոկտեմբեր',
      November: 'Նոյեմբեր',
      December: 'Դեկտեմբեր',

      //toast message
      operation_success: 'Հաջողությամբ ուղարկվեց',
      operation_fail: 'Ինչ որ բան չստացվեց: Խնդրում ենք կրկին փորձել',
      // admin columns
      title: 'Անվանում',
      status: 'Կարգավիճակ',
      created: 'Ստեղծվել է',
      actions: 'Գործողություններ',
      tag_name: 'Թեգի անուն',
      name: 'Անուն',
      // admin menu
      digital_library_aniv: ` էլ․-գրադարան "Անիվ"`,
      digital_library_articles: ` էլ․-գրադարան "Հոդվածներ"`,
      bibliography_books: 'Մատենագրություն/Գրքեր',
      bibliography_articles: 'Մատենագրություն/Հոդվածներ',
      // other
      firstname: 'Անուն',
      surname: 'Ազգանուն',
      patronymic: 'Հայրանուն',
      position: 'Աշխատանք/Պաշտոն',
      bio: 'Կենսագրություն',
      image: 'Նկար',
      cover: 'Կազմի նկար',
      country: 'Երկիր',
      council: 'Խորհուրդ',
      add_social: 'Ավելացնել սոց-ցանցի հղում',
      social_page_name: 'Սոց-ցանցի անվանում',
      social_pages: 'Սոց-ցանցեր',
      social_page_link: 'Հղում',
      source_name: 'Աղբյուրի անվանում',
      source_link: 'Աղբյուրի հղում',
      location: 'Տեղադրություն',
      start_end_date: 'Սկիզբ/Վերջ Ամսաթիվ',
      pub_house: 'Հրատարակչություն',
      date: 'Ամսաթիվ',
      docs: 'Փաստաթղթեր',
      link_name: 'Հղման տեքստ',
      link: 'Հղում',
      add_link: 'Ավելացնել հղում',
      add_record: 'Ավելացնել գրառում',
      click_to_upload: 'Սեղմեք բեռնելու համար',
      something_went_wrong: 'Ինչ որ բան այնպես չգնաց',
      description: 'Նկարագրություն',
      add_item: 'Ավելացնել կետ',
      date_to: 'Վերջնաժամկետ',
      max_file_size_5: 'Ֆայլի սխալ չափս։ Առավելագույն չափսը 5ՄԲ',
      activities: 'Գործունեություն',
      button_text: 'Կոճակի տեքստ',
      button_url: 'Կոճակի հղում',
    },
  },
};
