const key = 'activitiesYears';

export default (state) => ({
  // state selectors
  loaded: state[key].loaded,
  failed: state[key].failed,
  rows: state[key].data && state[key].data.rows,
  count: state[key].data && state[key].data.count,
  maxYear: state[key].loaded && Math.max(...state[key].data.rows),
  minYear: state[key].loaded && Math.min(...state[key].data.rows),
});
