import { FIND_NEWS_WEBSITE, NEWS } from '../actionTypes';
import { initialState } from '.';

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (true) {
    case [FIND_NEWS_WEBSITE.LOAD].includes(type):
      return { ...state, loaded: false };
    case [FIND_NEWS_WEBSITE.FAIL].includes(type):
      return { ...state, failed: true, loaded: true };
    case [FIND_NEWS_WEBSITE.SUCCES].includes(type):
      return {
        ...state,
        data: { ...(state.data ? { ...state.data, rows: [...state.data.rows, ...payload.rows] } : payload) },
        loaded: true,
      };
    // create
    case [NEWS.FIND.RESET].includes(type):
      return initialState
    default:
      return state;
  }
};
