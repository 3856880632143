export const root = `/`;
export const userApi = `/users`;
// authorization api
export const userLoginApi = `${userApi}/login`;
export const userLogoutApi = `${userApi}/logout`;

export const newsApi = `news`;
export const tagsApi = `tags`;
export const activitiesApi = `events`;
export const rubricsApi = `rubrics`;
export const docsApi = `documents`;
export const searchApi = `search`;
export const personsApi = `persons`;
export const projectsApi = `projects`;
export const prizesApi = `prizes`;
export const partnersApi = `partners`;
export const carouselApi = `carousel`;
export const domainApi = `domain`;
export const booksApi = `books`;
export const articlesApi = `articles`;
export const aboutApi = `about`;
export const organizationsApi = `org`;
export const membersApi = `members`;
export const countriesApi = `country`;
export const subscribeApi = `users/subscribe`;
export const vacanciesApi = `jobs`;
export const staticsApi = `statics`;
export const sliderApi = `sliders`;
