import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const top_block = document.getElementById('top');

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    top_block && top_block.scrollIntoView();
  }, [pathname]);
  return null;
}
